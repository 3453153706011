import React, { useEffect, useState } from 'react';
import Container from '../components/atoms/Container';
import Heading from '../components/atoms/Heading';
import Paragraph from '../components/atoms/Paragraph';
import Stats from '../components/molecules/Stats';
import Cards from '../components/organisms/Cards';
import Section from '../components/organisms/Section';
import StaticBanner from '../components/organisms/StaticBanner';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import PartnersScroll from '../components/templates/PartnersScroll';
import { STATS } from '../constants/stats';
import { DICT } from '../constants/dict';
import ContactForm from '../components/organisms/ContactForm';

// This is a special requirement. so when user redirects to website using a QR code a contact form appears.
function Modal({ show, onClose, children }) {
    if (!show) {
        return null;
    }
    return (
        <div className='modal-overlay'>
            <div className='modal-content'>
                <button
                    className='close-button'
                    onClick={onClose}>
                    &times;
                </button>
                {children}
            </div>
        </div>
    );
}

const Home = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const closeModal = () => {
        setIsModalOpen(false);
        navigate(window.location.pathname, { replace: true });
    };

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        if (queryParams.get('source') === 'qr') {
            setIsModalOpen(true);
        }
    }, [location]);

    return (
        <>
            <Modal
                show={isModalOpen}
                onClose={closeModal}>
                <Heading
                    type='h2'
                    className='center'
                    text='Contact Us'
                />
                <ContactForm closeModal={closeModal} />
            </Modal>
            <StaticBanner size='none'>
                <div className='hero-text'>
                    <Heading
                        type='h1'
                        text='Supply Chain Fintech Platform'
                    />
                    <Paragraph text='SaralSCF is a B2B fintech platform that partners with mid-size enterprises to address the working capital requirements in their supply chain, encompassing both payable and receivable management.' />
                </div>
                <div className='hero-stats'>
                    <Stats
                        items={[
                            {
                                icon: 'community-line',
                                value: STATS.all.anchors,
                                desc: DICT.total_anchors,
                            },
                            {
                                icon: 'user-line',
                                value: STATS.all.borrowers,
                                desc: DICT.total_borrowers,
                            },
                            {
                                icon: 'article-line',
                                value: STATS.all.invoices,
                                desc: DICT.total_invoices,
                            },
                            {
                                icon: 'send-plane-2-line',
                                value: STATS.all.disbursed,
                                desc: DICT.total_amount,
                                currency: true,
                            },
                            { icon: 'cash-line', value: STATS.all.aum, desc: DICT.current_aum },
                        ]}
                    />
                </div>
                <PartnersScroll
                    url='all'
                    type='live'
                />
            </StaticBanner>
            <div className='separator'></div>
            <Container className='text-center'>
                <Heading>SaralSCF Anchor-led Business</Heading>
                <Paragraph>
                    SaralSCF uses an Anchor-led business model, where a key enterprise, commonly
                    known as an anchor, acts as a central hub for capital flow to suppliers and
                    customers in the supply chain. The end-to-end process of SaralSCF is driven
                    through a robust tech-enabled platform. The digital process includes
                    identifying, assessing and onboarding anchors, disbursement and collection from
                    end borrowers.
                </Paragraph>
            </Container>
            <div className='separator'></div>
            <Container>
                <div
                    className='text-center'
                    id='saral-solutions'>
                    <Heading align='center'>SaralSCF Solutions</Heading>
                    <Paragraph>
                        SaralSCF meets its B2B customers' supply chain financing requirements
                        through its three solutions.
                    </Paragraph>
                </div>
                <br />
                <br />
                <Cards
                    config={{
                        colClass: 'col-4 col-xs-12 mb-10 text-center',
                        items: [
                            {
                                img: '/img/products/pay-later.png',
                                imgClass: 'cards__item__img-xs',
                                masterHeading: 'Saral Pay Later',
                                // badge: '₹420+ Crores',
                                desc: 'Solves the payable financing requirements of the customers within the supply chain while optimising receivables for Anchor and providing financial flexibility across the supply chain.',
                                // footer: 'Credit Line: 160+ Crores',
                                link: {
                                    href: '/saral-solutions/saral-pay-later',
                                    text: 'Read More',
                                },
                            },
                            {
                                img: '/img/products/vendor-finance.png',
                                imgClass: 'cards__item__img-xs',
                                masterHeading: 'Saral Vendor Finance',
                                // badge: '₹294+ Crores',
                                desc: 'Addresses the financing needs of suppliers within the supply chain. With flexible credit solutions, SaralSCF helps suppliers manage their cash flow more effectively.',
                                // footer: 'Credit Line: 200+ Crores',
                                link: {
                                    href: '/saral-solutions/saral-vendor-finance',
                                    text: 'Read More',
                                },
                            },
                            {
                                img: '/img/products/scf.png',
                                imgClass: 'cards__item__img-xs',
                                masterHeading: 'Saral Supply Chain Credit',
                                // badge: '₹225+ Crores',
                                desc: 'Assists Anchors in optimising their capital flow and liquidity management within the supply chain. It provides working capital solutions to anchor enterprises.',
                                // footer: 'Credit Line: 20+ Crores',
                                link: {
                                    href: '/saral-solutions/saral-supply-chain-credit',
                                    text: 'Read More',
                                },
                            },
                        ],
                    }}
                />
            </Container>
            <div className='separator'></div>
            <Container>
                <Section
                    config={{
                        align: 'center',
                        items: [
                            {
                                col: 6,
                                colXs: 12,
                                type: 'section',
                                align: 'center',
                                config: {
                                    items: [
                                        {
                                            type: 'heading',
                                            config: {
                                                text: 'How does the B2B Supply Chain work?',
                                                className: 'mb-10',
                                            },
                                        },
                                        {
                                            type: 'paragraph',
                                            config: {
                                                text: 'A typical B2B Supply Chain of a mid-sized enterprise consists of distributors and dealers. The flow of goods occurs on Day 0 when a Seller sells goods to a Buyer. With access to Supply Chain Finance, buyers can effectively fulfil their working capital requirements by managing their payments within the extended payment cycles, ranging from 7 days to 120 days. ',
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                col: 6,
                                colXs: 12,
                                type: 'image',
                                config: {
                                    src: '/img/products/1.png',
                                },
                            },
                        ],
                    }}></Section>
            </Container>
            <div className='separator'></div>
            <Container>
                <Section
                    config={{
                        align: 'center',
                        items: [
                            {
                                col: 6,
                                colXs: 12,
                                type: 'section',
                                align: 'center',
                                config: {
                                    items: [
                                        {
                                            type: 'heading',
                                            config: {
                                                text: 'How does SaralSCF help the Supply Chain Finance? ',
                                                className: 'mb-10',
                                            },
                                        },
                                        {
                                            type: 'paragraph',
                                            config: {
                                                text: 'The Supply Chain of any business requires working capital to operate until the goods reach end customers. SaralSCF helps supply chain businesses and their stakeholders by providing customised solutions to meet their unique needs. SaralSCF speeds up the payment process by making the payment on Day 0 to the sellers against the invoices raised and offers up to 120 days of credit duration to buyers for repayment. ',
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                col: 6,
                                colXs: 12,
                                type: 'image',
                                order: 2,
                                config: {
                                    src: '/img/products/2.png',
                                },
                            },
                        ],
                    }}></Section>
            </Container>
        </>
    );
};

export default Home;
