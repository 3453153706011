import React from 'react';
import Container from '../../components/atoms/Container';
import Heading from '../../components/atoms/Heading';
import StaticBanner from '../../components/organisms/StaticBanner';
import Cards from '../../components/organisms/Cards';

const Notices = () => {
    return (
        <>
            <StaticBanner size='xs'>
                <Container>
                    <div className='hero-text'>
                        <Heading type='h1'>Notices</Heading>
                    </div>
                </Container>
            </StaticBanner>
            <Container>
                <Cards
                    config={{
                        colClass: 'col-4 mb-20 col-xs-12',
                        items: [
                            {
                                heading: 'Notice of 3rd AGM',
                                desc: 'Notice of 3rd AGM (23rd September 2024)',
                                descIcon: 'article-line',
                                link: {
                                    text: 'Download File',
                                    href: '/files/notices/3rd_AGM_2024.pdf',
                                },
                            },
                            {
                                heading: 'Notice of 4th AGM',
                                desc: 'Notice of 4th AGM (12th September 2024)',
                                descIcon: 'article-line',
                                link: {
                                    text: 'Download File',
                                    href: '/files/notices/4th_AGM_2024.pdf',
                                },
                            },
                            {
                                heading: 'Notice of EGM',
                                desc: 'Notice of EGM (4th April 2024)',
                                descIcon: 'article-line',
                                link: {
                                    text: 'Download File',
                                    href: '/files/notices/notice_of_EGM.pdf',
                                },
                            },
                        ],
                    }}
                />
            </Container>
        </>
    );
};
export default Notices;
