import React, { useState } from 'react';
import axios from 'axios';
import Button from '../atoms/Button';
import { showSuccess } from '../../helpers/utils';

const ContactForm = ({ closeModal }) => {
    const [state, setState] = useState({ submitting: false, succeeded: false, errors: [] });
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        mobile: '',
        remarks: '',
        product_type: '',
    });

    const options = [
        {
            name: 'Product Type',
            value: '',
        },
        {
            name: 'Invoice Financing',
            value: 'invoice_financing',
        },
        {
            name: 'Vendor Financing',
            value: 'vendor_financing',
        },
        {
            name: 'Receivable Financing',
            value: 'receivable_financing',
        },
        {
            name: 'Term Loan',
            value: 'term_loan',
        },
        {
            name: 'RBD',
            value: 'rbd',
        },
    ];

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setState({ ...state, submitting: true });

        try {
            const response = await axios.post(
                'https://api.saraloan.in/api/v1/core/contacts/',
                formData
            );

            if (response.status === 201) {
                setState({ submitting: false, succeeded: true, errors: [] });
                showSuccess('Thank you for reaching out! We’ll get back to you soon.');
                closeModal();
            }
        } catch (error) {
            setState({
                ...state,
                submitting: false,
                errors: error.response ? error.response.data.errors : ['Submission failed'],
            });
        }
    };

    return (
        <form
            onSubmit={handleSubmit}
            className='form'>
            {state.errors.length > 0 && (
                <div className='error'>
                    {state.errors.map((error, index) => (
                        <p key={index}>{error}</p>
                    ))}
                </div>
            )}
            <input
                className='form__control'
                type='text'
                name='name'
                placeholder='Name'
                value={formData.name}
                onChange={handleChange}
                required
            />
            <input
                className='form__control'
                type='email'
                name='email'
                placeholder='E-Mail'
                value={formData.email}
                onChange={handleChange}
                required
            />
            <input
                className='form__control'
                type='text'
                name='mobile'
                placeholder='Mobile Number'
                value={formData.mobile}
                onChange={handleChange}
                required
            />
            <select
                className='form__control'
                name='product_type'
                value={formData.product_type}
                onChange={handleChange}
                required>
                {options.map((option) => (
                    <option
                        key={option.value}
                        value={option.value}>
                        {option.name}
                    </option>
                ))}
            </select>
            <textarea
                className='form__control'
                name='remarks'
                placeholder='Write your Message here'
                value={formData.remarks}
                onChange={handleChange}
                required></textarea>
            <Button disabled={state.submitting}>Request a Callback</Button>
        </form>
    );
};

export default ContactForm;
