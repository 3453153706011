import Swal from 'sweetalert2';

export const showSuccess = (message) => {
    let html = '';
    if (typeof message === 'object') {
        html += '<table class="table table-striped text-left"><tbody>';
        Object.entries(message).forEach(([key, value]) => {
            html += '<tr><td class="text-danger">' + key + '</td><td>' + value + '</td></tr>';
        });
        html += '</tbody></table>';
    }
    Swal.fire({
        icon: 'success',
        title: 'Success!',
        width: html ? '50rem' : '32rem',
        text: message,
        html: html,
        buttonsStyling: false,
        customClass: {
            confirmButton: 'btn btn-brand',
            cancelButton: 'btn btn-secondary',
        },
    });
};
