import React, { useState } from 'react';
import Container from '../components/atoms/Container';
import Heading from '../components/atoms/Heading';
import Paragraph from '../components/atoms/Paragraph';
import IconGroup from '../components/molecules/IconGroup';
import Cards from '../components/organisms/Cards';
import StaticBanner from '../components/organisms/StaticBanner';

const About = () => {
    const teams = [
        {
            title: 'Business',
            items: [
                {
                    img: '/img/team/ashutosh.png',
                    heading: 'Ashutosh Kumar',
                    subheading: 'Vice President, Portfolio Monitoring',
                    olx: [
                        'Product & Regional Sales Manager at ICICI Bank (4 Years)',
                        'Senior VP at Yes Bank (5 Yrs)',
                        'Senior VP at Fullerton India Credit Company Ltd (2 Yrs)',
                        'Chief Business  Officer at Unogrowth Technologies Pvt Ltd (LoanSimple) (3 yrs)',
                    ],
                    footer: 'Previously - ICICI Bank, Yes Bank and Fullerton India',
                },
                {
                    img: '/img/team/shubh.jpg',
                    heading: 'Shubh Dayama',
                    subheading: 'Asst. Vice President, Business',
                    footer: 'Previously - Fasttrack HFL',
                },
            ],
        },
        {
            title: 'Technology',
            items: [
                {
                    img: '/img/team/jayant.png',
                    heading: 'Jayant K. Sahoo',
                    subheading: 'Asst. Vice President, Technology',
                    olx: ['Data Ananlyst at Unogrowth Technologies Pvt Ltd (LoanSimple) - 3 Yrs'],
                    footer: 'Previously - Aunde India Ltd.',
                },
                {
                    img: '/img/team/amit.png',
                    heading: 'Amit Kumar',
                    subheading: 'Tech Lead',
                    olx: ['Data Ananlyst at Unogrowth Technologies Pvt Ltd (LoanSimple) - 3 Yrs'],
                    footer: 'Previously - Agatsa Software Pvt. Ltd.',
                },
                {
                    img: '/img/team/rishabh.png',
                    heading: 'Rishabh Gupta',
                    subheading: 'Tech Lead',
                    olx: ['Data Ananlyst at Unogrowth Technologies Pvt Ltd (LoanSimple) - 3 Yrs'],
                    footer: 'Previously - Industrybuying.com',
                },
                {
                    img: '/img/team/sanchay.png',
                    heading: 'Sanchay Bhargwa',
                    subheading: 'Product Manager',
                    olx: ['Data Ananlyst at Unogrowth Technologies Pvt Ltd (LoanSimple) - 3 Yrs'],
                    footer: 'Previously - Bambinos Learning Solutions',
                },
            ],
        },
        {
            title: 'Operations',
            items: [
                {
                    img: '/img/team/pramila.jpg',
                    heading: 'Pramila Dabhekar',
                    subheading: 'Vice President, Finance & Operations',
                    footer: 'Previously - Light Craft and Sound',
                    olx: [
                        'Finance & Internal Control- Manager at Light Craft and Sound Pvt Ltd (2 years)',
                        'Industrial Training Bank of America Merill Lynch (10 months)',
                        'CA intership/articleship at mid size firm ( 3 years)',
                    ],
                },
                {
                    img: '/img/team/mahesh.png',
                    heading: 'Mahesh Kumar Yadav',
                    subheading: 'Asst. Vice President, Operations',
                    footer: 'Previously - LoanSimple, I-Loan Credit Pvt Ltd',
                    olx: [
                        'Direct & Indirect Tax Manager at Just Archon LLP (2 Years)',
                        'CA intership/articleship at mid size firm (3 years)',
                    ],
                },
            ],
        },
    ];
    const [selectedTeam, setSelectedTeam] = useState(teams[0]);
    return (
        <>
            <StaticBanner size='xs'>
                <Container>
                    <div className='hero-text'>
                        <Heading type='h1'>About Us</Heading>
                    </div>
                </Container>
            </StaticBanner>
            <Container>
                <Paragraph>
                    SaralSCF Technologies Pvt. Ltd is a wholly owned subsidiary of Blacksoil
                    Capital. This was founded by Sanny Chaudhary and Daman Singh Kohli with a vision
                    to create "saralSCF", a cutting-edge Supply Chain Fintech platform. In 2021,
                    Blacksoil Capital, an NBFC, joined forces through a management buyout, and
                    SaralSCF Technologies became a wholly owned subsidiary of Blacksoil Capital. The
                    platform is presently known as SaralSCF.
                </Paragraph>
                <br />
                <Paragraph>
                    BlackSoil Capital, specialising in providing alternative credit to Growth
                    Companies (GC) and debt to Financial Institutions (FI), expanded its offerings
                    by adding a new business vertical of Supply Chain Financing.
                </Paragraph>
                <br />
                <Paragraph>
                    India's supply chain finance market is experiencing exponential growth, driven
                    by the growing awareness of the numerous advantages of this financing method.
                    Key catalysts for this growth include the rising demand for working capital
                    financing and the rapid adoption of digital technologies. The Indian government,
                    recognising the potential, has played a pivotal role in promoting supply chain
                    finance through initiatives like the Trade Receivables Discounting System
                    (TReDS). TReDS is a pioneering online platform that streamlines the financing of
                    invoices for Micro, Small, and Medium Enterprises (MSMEs), thus significantly
                    enhancing their cash flow.
                </Paragraph>
                <br />
                <Paragraph>
                    SaralSCF is well-positioned to flourish in this thriving ecosystem. India's
                    ambitious 'Make in India' initiative is set to create an even more favourable
                    environment for the supply chain finance market by stimulating domestic
                    production and manufacturing. This alignment with a national mission underscores
                    our commitment to contributing to India's economic growth.
                </Paragraph>
                <br />
                <Paragraph>
                    At SaralSCF, we are dedicated to catering to the unique technological needs of
                    the supply chain ecosystem. We do this by enhancing the efficiency of the supply
                    chain financing process providing seamless end-to-end digital solutions. Our
                    vision is to be at the forefront of India's supply chain finance revolution,
                    driving progress and prosperity through innovation and technology.
                </Paragraph>
            </Container>
            <Container
                size='lg'
                bg='#001555'
                className='container-dark color-white'>
                <Heading className='text-center mb-20'>Directors</Heading>
                <Cards
                    config={{
                        colClass: 'col-3 col-xs-12',
                        items: [
                            {
                                img: '/img/team/mp-bansal.jpg',
                                heading: 'M P Bansal',
                                subheading: 'Founding Partner of BlackSoil Group',
                                desc: 'An industry veteran with 40+ years of experience in fund management, strategic advisory, capital markets, M&A, post-acquisition integration, and performance tracking across Asia and Europe.',
                                footer: 'Independent Directorships - Navneet Education Limited, AllCargo Logistics Limited',
                            },
                            {
                                img: '/img/team/ankur.jpg',
                                heading: 'Ankur Bansal',
                                subheading: 'Co-founder and Director of BlackSoil Group',
                                desc: 'An entrepreneur with 18+ years of experience in idea origination, credit, M&A execution, investment thesis, commercial negotiations, and post-deal investment management.',
                                footer: 'Previously - J.P. Morgan and Citi Group',
                            },
                            {
                                img: '/img/team/gaurav.jpg',
                                heading: 'Gaurav Bagrodia',
                                subheading: 'President, Business of SaralSCF',
                                desc: 'A professional with 20+ years of experience in business development, credit, risk, policy, and collections across financial products including working capital, secured & unsecured loans.',
                                footer: 'Previously - L&T Financial Services and Fullerton',
                            },
                        ],
                    }}
                />
            </Container>
            <div className='separator'></div>
            <Container size='md'>
                <div className='teams'>
                    <ul className='teams__nav'>
                        {teams.map((team) => {
                            return (
                                <li
                                    className={`teams__nav__item ${
                                        selectedTeam.title === team.title ? 'active' : ''
                                    }`}
                                    key={team.title}
                                    onClick={() => setSelectedTeam(team)}>
                                    {team.title}
                                </li>
                            );
                        })}
                    </ul>
                    <div className=''>
                        <Cards
                            config={{
                                class: 'row',
                                colClass: 'col-3 mb-30 col-xs-6',
                                items: selectedTeam.items,
                            }}
                        />
                    </div>
                </div>
            </Container>
            <div className='separator'></div>
            <Container className='text-center'>
                <Heading>Saral Values</Heading>
                <IconGroup
                    config={{
                        className: 'mt-50',
                        itemClass: 'col-xs-12',
                        items: [
                            {
                                class: 'col-xs-12',
                                icon: '/img/values/integrity.png',
                                heading: 'Fiduciary Responsibility',
                                desc: 'We act as trustees for our stakeholders with a firm sense of accountability for the resources we use and the systems and processes we deploy.',
                            },
                            {
                                icon: '/img/values/people-first.png',
                                heading: 'People First',
                                desc: 'We prioritize to support such businesses that contributes for the people and the society.',
                            },
                            {
                                icon: '/img/values/entreprenure-centric.png',
                                heading: 'Entrepreneur Centric',
                                desc: 'Recognizing the value of entrepreneurship and aligning with their unique needs and aspirations.',
                            },
                        ],
                    }}
                />
                <IconGroup
                    className='mt-50'
                    config={{
                        className: 'mt-50',
                        itemClass: 'col-xs-12',
                        items: [
                            {
                                icon: '/img/values/transparency.png',
                                heading: 'Transparency',
                                desc: 'We maintain transparency and fairness in our operations, and follow robust governance practices.',
                            },
                            {
                                icon: '/img/values/sustainability.png',
                                heading: 'Environmental Sustainability',
                                desc: 'Committing to safeguard the environment through responsible investing and integrating sustainability through our efforts.',
                            },
                            {
                                icon: '/img/values/collaboration.png',
                                heading: 'Collaboration',
                                desc: 'We look for opportunities to collaborate with organizations that share our values, drive meaningful impact and our collective strength brings positive change.',
                            },
                        ],
                    }}
                />
            </Container>
        </>
    );
};

export default About;
