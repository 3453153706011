import React from 'react';
import Container from '../../components/atoms/Container';
import Heading from '../../components/atoms/Heading';
import StaticBanner from '../../components/organisms/StaticBanner';
import Cards from '../../components/organisms/Cards';

const Return = () => {
    return (
        <>
            <StaticBanner size='xs'>
                <Container>
                    <div className='hero-text'>
                        <Heading type='h1'>Annual Returns</Heading>
                    </div>
                </Container>
            </StaticBanner>
            <Container>
                <Cards
                    config={{
                        colClass: 'col-4 mb-20 col-xs-12',
                        items: [
                            {
                                // icon: 'file-mark-line',
                                heading: '2020-21',
                                desc: 'Annual Return for the Financial Year 2020-21',
                                descIcon: 'article-line',
                                link: {
                                    text: 'Download File',
                                    href: '/files/fy_2020_21.pdf',
                                },
                            },
                            {
                                // icon: 'file-mark-line',
                                heading: '2021-22',
                                desc: 'Annual Return for the Financial Year 2021-22',
                                descIcon: 'article-line',
                                link: {
                                    text: 'Download File',
                                    href: '/files/fy_2021_22.pdf',
                                },
                            },
                            {
                                // icon: 'file-mark-line',
                                heading: '2022-23',
                                desc: 'Annual Return for the Financial Year 2022-23',
                                descIcon: 'article-line',
                                link: {
                                    text: 'Download File',
                                    href: '/files/fy_2022_23.pdf',
                                },
                            },
                            {
                                // icon: 'file-mark-line',
                                heading: '2023-24',
                                desc: 'Annual Return for the Financial Year 2023-24',
                                descIcon: 'article-line',
                                link: {
                                    text: 'Download File',
                                    href: '/files/fy_2023_24.pdf',
                                },
                            },
                        ],
                    }}
                />
            </Container>
        </>
    );
};
export default Return;
