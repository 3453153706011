export const STATS = {
    // Home page
    all: {
        anchors: 133,
        borrowers: 17699,
        invoices: 310408,
        disbursed: 24437108742.45,
        aum: 3152178614.0,
    },
    // SaralPay Later
    bnpl: {
        anchors: 22,
        borrowers: 4569,
        invoices: 24602,
        disbursed: 8469014535.78,
        aum: 538508561.0,
    },
    // Vendor Finance
    vendor: {
        anchors: 8,
        borrowers: 96,
        invoices: 821,
        disbursed: 5960924569.34,
        aum: 996832989.0,
    },
    // Supplychain finance
    scf: {
        anchors: 20,
        borrowers: 139,
        invoices: 911,
        disbursed: 10007169276.0,
        aum: 1616837064.0,
    },
};
