import React from 'react';
import Container from '../components/atoms/Container';
import Heading from '../components/atoms/Heading';
import Image from '../components/atoms/Image';
import StaticBanner from '../components/organisms/StaticBanner';
import Paragraph from '../components/atoms/Paragraph';
import Link from '../components/atoms/Link';
const Terms = () => {
    return (
        <>
            <StaticBanner size='xs'>
                <Container>
                    <div className='hero-text'>
                        <Image
                            className='shape'
                            src='/img/shape-2.png'
                        />
                        <Heading type='h1'>Terms & Conditions</Heading>
                        <Image
                            className='shape'
                            src='/img/shape-1.png'
                        />
                    </div>
                </Container>
            </StaticBanner>
            <Container size='md'>
                <Paragraph className='mb-20'>
                    Applicant Declaration (forming part of the [Facility Application Form]) [I/We],
                    ("the Applicant(s)"), hereby unconditionally and irrevocably declare and confirm
                    that:
                </Paragraph>
                <Paragraph className='mb-20'>
                    The application for a lending facility ("Facility") is made to a lender, or any
                    person(s) who provides a facility pursuant to this declaration ("Lender", which
                    expression, wherever the context requires shall mean more than 1 (One) Lender)
                    with the assistance of SaralSCF Technologies Pvt. Ltd.(hereafter referred to as
                    SaralSCF), which is engaged in designing, developing, hosting, and providing
                    technology platform(s) which collect information, data and documents relating to
                    persons from various sources and in analysing, processing, and disseminating
                    such information, data, analysis and/or documents to Lenders to enable such
                    other Lenders to provide various financial and other services ("SaralSCF
                    Technologies Private limited"). SaralSCF has tied up with lending partners to
                    ensure you get the best financing options. Please refer below for our complete
                    list of lending partners.
                </Paragraph>
                <div className='mt-20'>
                    <Link
                        herf='http://blacksoil.co.in'
                        target='_blank'
                        className='color-bcpl'>
                        Blacksoil Capital Pvt. Ltd.
                    </Link>
                </div>
                <div className='mb-20'>
                    <Link
                        herf='https://www.niyogin.com/'
                        target='_blank'
                        className='color-link mt-20'>
                        Niyogin Fintech Ltd.
                    </Link>
                </div>
                <Paragraph className='mb-20'>
                    Pursuant to [my/our] application for the Facility, [I/we] make this declaration
                    for the benefit and reliance of the Lender and SaralSCF.
                </Paragraph>
                <Paragraph className='mb-20'>
                    [I/We] shall submit, sign and execute all such facility agreements and other
                    documents as may be prescribed and/or required by the Lender, in its discretion,
                    in relation to availing such a Facility at any point of time and the applicable
                    stamp duty in which regard shall be payable by [me/us].
                </Paragraph>
                <Paragraph className='mb-20'>
                    [I/We] fully acknowledge that the grant of the Facility is at the sole
                    discretion of Lender and is subject to [my/our] execution of documents deemed
                    necessary by the Lender and [my/our] fulfilment of other closing formalities as
                    required by, and to the satisfaction of, the Lender in this regard. We also
                    acknowledge that the Lender reserves the right to reject [my/our] application
                    for such a Facility without assigning any reasons whatsoever, and that [I/we]
                    reserve no right to appeal against such a decision of the Lender. [I/We] further
                    acknowledge that the processing fee given by [me/us] in relation to the
                    application for such a Facility is non-refundable and in case of the rejection
                    of [my/our] application, [I/we] shall not seek a refund of the same.
                </Paragraph>
                <Paragraph className='mb-20'>
                    [I/We] declare that [I am/we are] [citizen(s) of/resident(s) in] India and
                    [I/we] declare, warrant and represent that all the particulars and information
                    and details provided in the application form to avail the Facility and documents
                    given by [me/us] to the Lender and/or SaralSCF in connection therewith, are
                    true, correct, accurate and up-to-date in all respects and that [I/we] have not
                    withheld or suppressed any material information from the Lender or SaralSCF in
                    this regard. [I/ We] further acknowledge that all the information given by
                    [me/us] in the application for a Facility upon the Lender’s request, shall form
                    the basis of any loan or facility that the Lender may decide to grant to
                    [me/us]. We understand and acknowledge that the Lender and/or SaralSCF reserve
                    the right to retain all applicable information, photographs and other documents
                    submitted by [me/us] (forming part of such application and this declaration) and
                    may not return the same to [me/us].
                </Paragraph>
                <Paragraph className='mb-20'>
                    [I/We] confirm that there are no insolvency proceedings against [me/us] nor have
                    [I/we] ever been adjudicated insolvent by any judicial or other authority under
                    applicable law. [I/We] also undertake to inform the Lender of changes in
                    [my/our] occupation or employment and to provide any further information that
                    the Lender may in its discretion require. [I/We] shall further inform the Lender
                    on the change in [my/our] resident status, control, capital structure,
                    constitution and management (wherever applicable).
                </Paragraph>
                <Paragraph className='mb-20'>
                    [I/We] hereby agree and give [my/our] consent for the disclosure by the Lender
                    and/or SaralSCF, waiving specifically [my/our] right of notice, privacy,
                    privity, defamation for such disclosure by the Lender and/or SaralSCF, of all or
                    any of the following:
                </Paragraph>
                <Paragraph className='mb-20'>
                    information and data relating to [me/us] as well our directors and group
                    companies (as applicable) or obligations to be assumed by [me/us], our directors
                    and/or our group companies (as applicable) in connection with the Facility to be
                    availed by [me/us] as well as any other information required to provided
                    pursuant to such a Facility and as required by the Lender and/or SaralSCF; and
                    (b) Any default committed by [me/us] and/or non-performance of our obligations
                    prescribed in any facility document or other agreement executed pursuant to the
                    Lender’s instruction (as determined by the Lender in its sole discretion) in
                    relation to the Facility that will be granted to [me/us] upon the successful
                    processing of [my/our] application by the Lender, and as the Lender may deem
                    appropriate and necessary to disclose and furnish to
                </Paragraph>
                <Paragraph className='mb-20'>
                    The Reserve Bank of India ("RBI") or the Credit Information Bureau (India)
                    Limited ("CIBIL") and/ or any other agency authorized in this behalf by RBI or
                    any regulatory, supervisory or other authority, court of law, tribunal or
                    person, in India or any other jurisdiction, where such disclosure is required by
                    law, regulation, judgment or order of court or order of any tribunal;
                </Paragraph>
                <Paragraph className='mb-20'>
                    any actual or potential assignee(s) or novatee(s) or transferee(s) of any rights
                    and obligations of the Lender or other participants in any of its rights and/or
                    obligations under or relating to the Facility for any purposes connected with
                    the proposed assignment or transfer; or SaralSCF. Provided that SaralSCF
                    Technologies Private limited may use, disclose and/or process such information
                    and data disclosed by the Lender in the manner as it deems fit.
                </Paragraph>
                <Paragraph className='mb-20'>
                    [I/We] also acknowledge that the Lender and/or SaralSCF shall have the right to
                    seek information pertaining to [me/us] from regulatory authorities and other
                    third parties including, alternate sources (Trusting Social score) to establish
                    credit worthiness, inter-alia, the RBI, the CIBIL, Experian, CRIF Highmark,
                    Equifax etc. in accordance with applicable law.
                </Paragraph>
                <Paragraph className='mb-20'>
                    [I/We] have no objection to furnish documents deemed necessary by the Lender
                    and/or required as per 'Know Your Customer' guidelines issued by RBI.
                </Paragraph>
                <Paragraph className='mb-20'>
                    [I/We] further acknowledge and appreciate that this declaration along with the
                    application for a Facility can be routed to multiple Lenders at the discretion
                    of SaralSCF Technologies Private limited.
                </Paragraph>
                <Paragraph className='mb-20'>
                    [I/We] acknowledge and appreciate that the Lender and SaralSCF will have right
                    to seek information related to the business transactions from [my/our] business
                    partners for the purpose of the sanction of Facility and ongoing monitoring in
                    relation thereto.
                </Paragraph>
                <Paragraph className='mb-20'>
                    [I/We] further acknowledge and declare that the funds availed by [me/us]
                    pursuant to the Facility shall be used for the purpose for which such a Facility
                    has been granted and will not be used for any speculative, prohibited,
                    forbidden, illegal, unlawful or anti-social purposes whatsoever.
                </Paragraph>
                <Paragraph className='mb-20'>
                    By accepting the terms and conditions, [I/We] [am/are] deemed to have accepted,
                    read, understood and agreed to be bound by the terms (including amendments
                    thereof) governing the Facility as determined by the Lender in its sole
                    discretion.
                </Paragraph>
                <Paragraph className='mb-20'>
                    Further, [I/we] have no objection to the Lender and/or SaralSCF from sending
                    [me/us] promotional offers of their products or services (including those of the
                    subsidiaries, affiliates or associate companies of the Lender and/or the
                    Company). [I/We] further acknowledge that the use of the above information shall
                    not amount to violation of any of [my/our] rights or privileges pertaining to
                    privacy or confidentiality. [I/We] further confirm that the privacy rights
                    available to us under, inter-alia, the Telecom Regulatory Authority of India
                    Act, 1997 and the Telecom Commercial Communication Customer Preference
                    Regulations, 2010, and any statutory amendments thereto, will not be exercised
                    by [me/us] in relation to any promotional offers or information received from
                    the Lender and/or SaralSCF and their subsidiaries, affiliates or associate
                    companies. [I/We] accord the above consent, notwithstanding the grant of
                    Facility, its rejection by the Lender, or the maturity of such Facilities and
                    authorise Lender and/or SaralSCF Technologies Private limited to use the said
                    information.
                </Paragraph>
                <Heading type='h4'>UIDAI RELATED DECLARATIONS</Heading>
                <Paragraph className='mb-20'>
                    [I/we] holder of the Aadhaar number stated herein, hereby voluntary give my/our
                    consent to SaralSCF Technologies Private limited/Lender to obtain and use my/our
                    Aadhaar number /Name and Fingerprints/Iris and my/our Aadhar details to
                    authenticate me/us with unique identification Authority of India (UIDAI) as per
                    Aadhaar Act,2016 and all other applicable laws ('Aadhar Details'). [I/we]
                    understand that furnishing of Aadhaar Details is mandatory for the processing of
                    my/our loan and related services.
                </Paragraph>
                <Paragraph className='mb-20'>
                    SaralSCF/Lender has informed me/us that my/our Aadhaar details and identity
                    information would only be used for demographic authentication, validation, e-KYC
                    purpose and OTP authentication for availing services from SaralSCF Technologies
                    Private limited/Lender. A record of the search may be retained as per applicable
                    laws for usage by SaralSCF/Lender for KYC verification or Aadhar based
                    authentication or for enforcement /compliance of SaralSCF Technologies Private
                    limited/Lender rights and obligations under applicable laws. [I/we] shall not
                    hold SaralSCF [or any of its group/ related entities/ or its their agents/
                    representatives/ service provider] liable for the use/ sharing of the
                    information and details for any purpose mentioned herein in accordance with
                    applicable laws.
                </Paragraph>
                <Paragraph className='mb-20'>
                    [I/we] hereby give my expressed and unequivocal consent to SaralSCF/Lender to
                    retrieve/ procure such data/ details from UIDAI for the purposes of verifying
                    my/our data/details/identity. I hereby further authorize SaralSCF/Lender and its
                    service providers that to do so. SaralSCF Technologies Private limited/Lender
                    and/or the service provider may check the details that [I/we] have supplied
                    against any particulars to UIDAI
                </Paragraph>
                <Paragraph className='mb-20'>
                    [I/we] hereby state that [I/we] have no objection in authenticating my/our
                    details with Aadhar based authentication system and hereby consent to and
                    authorize SaralSCF Technologies Private limited/Lender to retrieve my/our
                    personal details from UIDAI for Aadhar based authentication for the purpose of
                    generation of E-sign, for registration of e-mandate and for validating my
                    consent to the terms and conditions of the definitive agreements for the
                    services of SaralSCF/Lender.
                </Paragraph>
                <Paragraph className='mb-20'>
                    SaralSCF/Lender has also informed that my/our biometric details will not be
                    stored /shared and will be submitted to Central Identities Data Repository
                    (CIDR) only for the purpose of authentication.
                </Paragraph>
                <Paragraph className='mb-20'>
                    [I/we] also authorize SaralSCF/Lender to link and authenticate my/our Aadhaar no
                    to all its services of SaralSCF/Lender as may be opened in the future in
                    addition to the those mentioned herein
                </Paragraph>
                <Paragraph className='mb-20'>
                    [I/we] declare that the details submitted by me/us in the Aadhaar letter are
                    accurate, current and complete. [I/we] will not hold SaralSCF/Lender and any of
                    its officials responsible in case of any incorrect information provided by
                    me/us.
                </Paragraph>
                <Paragraph className='mb-20'>
                    [I/we] understand that SaralSCF/Lender and the service providers shall ensure
                    security, protection, confidentiality and access of my/our personal data
                    provided for the purpose of Aadhaar based authentication / KYC verification and
                    other services as mentioned above in accordance with applicable laws.
                </Paragraph>
                <Paragraph className='mb-20'>
                    [I/we] agree that the information shared under this declaration shall be
                    retained/ stored/ transmitted/ furnished by SaralSCF Technologies Private
                    limited as per applicable laws.
                </Paragraph>
                <Paragraph className='mb-20'>
                    By signing/ accepting the terms and conditions, [I/We] [am/are] deemed to have
                    accepted, read, understood and agreed to be bound by the terms (including
                    amendments thereof) governing the Facility as determined by the Lender in its
                    sole discretion.
                </Paragraph>
                <Paragraph className='mb-20'>
                    In this regard, [I/we] represent and warrant that the declarations made above by
                    [me/us] have been made in [my/our] true and lawful capacity and further
                    recognize that the Lender and/or SaralSCF Technologies Private limited will rely
                    on the correctness and accuracy of the declarations made by [me/us] in this
                    declaration. [I/We] acknowledge that any inaccuracies or falsehoods in the
                    declarations made by [me/us] and/or information provided by [me/us] shall give
                    an unconditional right to the Lender, at any point in time, to cancel the
                    Facility and exercise all rights available to such Lender against [me/us] under
                    applicable law.
                </Paragraph>
            </Container>
        </>
    );
};
export default Terms;
