import React from 'react';
import Container from '../../components/atoms/Container';
import Heading from '../../components/atoms/Heading';
import StaticBanner from '../../components/organisms/StaticBanner';
import Cards from '../../components/organisms/Cards';

const Policies = () => {
    return (
        <>
            <StaticBanner size='xs'>
                <Container>
                    <div className='hero-text'>
                        <Heading type='h1'>Policies</Heading>
                    </div>
                </Container>
            </StaticBanner>
            <Container>
                <Cards
                    config={{
                        colClass: 'col-4 mb-20 col-xs-12',
                        items: [
                            {
                                // icon: 'file-mark-line',
                                heading: 'Grievance Redressal Policy',
                                desc: 'approved and mandated by Board of Directors.',
                                descIcon: 'article-line',
                                link: {
                                    text: 'View Policy',
                                    href: '/files/group-policies/Grievance-Redressal-Policy.pdf',
                                },
                            },
                        ],
                    }}
                />
            </Container>
        </>
    );
};
export default Policies;
